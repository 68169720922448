<template>
  <Form
    @submit="onSave"
    :validation-schema="{
      departureKey: 'required',
      advance: 'required|decimal',
    }"
  >
    <div>
      <h4 class="mb-6 text-secondary">
        {{ action === "create" ? "Crear" : "Editar" }} avance partida
      </h4>
    </div>
    <div class="pb-4">
      <label class="text-dark font-semibold text-sm">Partida</label>
      <Field v-model="departureKey" name="departureKey" v-slot="{ field }">
        <select
          v-bind="field"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded-sm
            border
            outline-none
            text-gray-700
            placeholder-gray-400
          "
          placeholder="Seleccione"
        >
          <option
            v-for="departure in getDeparturesIsToCalculateIp"
            :key="departure.key"
            :value="departure.key"
          >
            {{ departure.name }}
          </option>
        </select>
      </Field>
      <div>
        <ErrorMessage name="departureKey" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm text-left">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="pb-4">
      <label class="text-dark font-semibold text-sm">Avance</label>
      <Field v-model="advance" name="advance" v-slot="{ field }">
        <input
          v-bind="field"
          type="text"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
            mt-2
          "
          placeholder="Ingrese avance"
        />
      </Field>
      <div>
        <ErrorMessage name="advance" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm text-left">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>

    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div class="text-left pb-10">
      <button
        class="rounded bg-primary py-2 px-8 text-white font-medium flex"
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  sectorDepartureAdvanceSelected: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["onComplete"]);
const store = useStore();
const { value: departureKey } = useField("departureKey");
const { value: advance } = useField("advance");

const loadingSave = ref(false);
const responseType = ref(null);
const responseMessage = ref(null);

const getDeparturesIsToCalculateIp = computed(
  () => store.getters.getDeparturesIsToCalculateIp
);

const init = async () => {
  await store.dispatch("getAllDepartures", { includeSubdepartures: true });

  if (props.action === "update") {
    departureKey.value = props.sectorDepartureAdvanceSelected.departureKey;
    advance.value = props.sectorDepartureAdvanceSelected.advance;
  }
};

init();

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (props.action === "create")
      await store.dispatch("createSectorDepartureAdvance", {
        sectorDepartureAdvance: {
          departureKey: values.departureKey,
          sectorKey: props.sectorDepartureAdvanceSelected.sectorKey,
          advance: values.advance,
        },
      });
    else
      await store.dispatch("updateSectorDepartureAdvance", {
        sectorDepartureAdvance: {
          key: props.sectorDepartureAdvanceSelected.key,
          advance: values.advance,
        },
      });

    resetForm();

    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message ||
      "Hubo un problema al guardar la información del sector/lote";
  } finally {
    loadingSave.value = false;
  }
};
</script>
