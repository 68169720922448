<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Sectores/Lotes</h2>
    <div class="py-2 text-left">
      <input
        type="checkbox"
        class="checked:bg-primary checked:border-transparent cursor-pointer ..."
        :checked="useSectors"
        @click="onSelectSectors"
      />
      <span class="select-none"> Incluir sector/lote en tareo </span>
    </div>
    <div class="flex">
      <button
        class="
          rounded
          border border-primary
          bg-white
          py-2
          px-4
          text-primary
          font-medium
          flex
          mr-3
        "
        @click="onOpenDrawerSector({ option: 'create' })"
      >
        Crear sector/lote
      </button>
      <button
        class="
          rounded
          border border-primary
          bg-primary
          py-2
          px-4
          text-white
          font-medium
          flex
        "
        @click="onOpenDrawerUploadSectorDepartureAdvance"
      >
        Importar avances
      </button>
    </div>
  </div>

  <div
    v-if="getSectors"
    class="w-full lg:pr-10 pr-5 lg:pl-5 pl-0 relative overflow-x-hidden"
  >
    <div class="relative overflow-x-hidden">
      <table class="w-full">
        <thead class="w-full">
          <tr class="flex">
            <th class="w-12"></th>
            <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
              Nombre
            </th>
            <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
              Código
            </th>
            <th class="px-6 py-2 text-sm text-gray-500 text-left flex-1">
              Etapa
            </th>
            <th class="px-6 py-2 text-sm text-gray-500 text-left w-80"></th>
          </tr>
        </thead>
        <tbody class="bg-white w-full">
          <template v-for="(sector, ind) in getSectors" :key="ind">
            <tr class="flex">
              <td class="px-4">
                <button @click="toggleCollapse(sector)" class="pt-4">
                  <svg
                    :class="{ 'transform rotate-180': sector.isCollapsed }"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </td>
              <td
                class="
                  text-left text-secondary
                  font-light
                  px-6
                  py-4
                  text-sm
                  flex-1
                "
              >
                {{ sector.name }}
              </td>
              <td
                class="
                  text-left text-secondary
                  font-light
                  px-6
                  py-4
                  text-sm
                  flex-1
                "
              >
                {{ sector.code }}
              </td>
              <td
                class="
                  text-left text-secondary
                  font-light
                  px-6
                  py-4
                  text-sm
                  flex-1
                "
              >
                {{ sector.stage }}
              </td>
              <td class="px-6 py-4 w-80">
                <div class="flex md:justify-end">
                  <a
                    class="
                      text-primary
                      font-bold
                      cursor-pointer
                      hover:bg-blue-50
                      px-2
                      mr-2
                    "
                    @click="
                      onOpenDrawerSectorDepartureAdvance({
                        action: 'create',
                        sectorDepartureAdvance: { sectorKey: sector.key },
                      })
                    "
                  >
                    Agregar avance partida
                  </a>
                  <a
                    class="
                      text-primary
                      font-bold
                      cursor-pointer
                      text-right
                      hover:bg-blue-50
                      px-2
                      my-auto
                    "
                    @click="
                      onOpenDrawerSector({
                        action: 'update',
                        sector: sector,
                      })
                    "
                  >
                    <p>Editar</p>
                  </a>
                </div>
              </td>
            </tr>
            <!-- Collapsible Content Container -->
            <tr v-if="sector.isCollapsed" class="flex flex-col px-20 py-4 mb-2">
              <td colspan="100%">
                <div class="pb-5">
                  <h3 class="text-primary">Partidas</h3>
                </div>
                <div
                  v-if="
                    sector.sectorDepartureAdvances &&
                    sector.sectorDepartureAdvances.length
                  "
                >
                  <table class="w-full">
                    <thead class="w-full">
                      <tr class="flex">
                        <th
                          class="
                            pr-5
                            py-2
                            text-sm text-gray-500 text-left
                            flex-1
                          "
                        >
                          <p>Nombre</p>
                        </th>
                        <th
                          class="
                            px-6
                            py-2
                            text-sm text-gray-500 text-left
                            flex-1
                          "
                        >
                          <p>Avance presupuesto</p>
                        </th>
                        <th
                          class="
                            px-6
                            py-2
                            text-sm text-gray-500 text-left
                            w-120
                          "
                        ></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white w-full">
                      <template
                        v-for="(
                          sectorDepartureAdvance, i
                        ) in sector.sectorDepartureAdvances"
                        :key="i"
                      >
                        <tr class="flex">
                          <td
                            class="
                              text-left text-secondary
                              font-light
                              pr-5
                              py-4
                              mr-4
                              flex-1
                            "
                          >
                            <p class="text-sm">
                              {{ sectorDepartureAdvance.departure.name }}
                            </p>
                          </td>
                          <td
                            class="
                              text-left text-secondary
                              font-light
                              px-6
                              py-4
                              flex-1
                            "
                          >
                            <p class="text-sm">
                              {{ sectorDepartureAdvance.advance }}
                            </p>
                          </td>
                          <td class="py-4">
                            <a
                              class="
                                text-primary
                                font-bold
                                cursor-pointer
                                hover:bg-blue-50
                                px-2
                                my-auto
                              "
                              @click="
                                onOpenDrawerSectorDepartureAdvance({
                                  action: 'update',
                                  sectorDepartureAdvance:
                                    sectorDepartureAdvance,
                                })
                              "
                            >
                              Editar
                            </a>
                            <!--
                            <button class="ml-4 hover:bg-red-50 px-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                                  fill="#E00F57"
                                />
                              </svg>
                            </button>
                            -->
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div v-else><p>No hay avance de partidas</p></div>
                <!-- Your collapsible content here -->
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer :open="drawerSector" @update:open="drawerSector = $event">
    <SectorForm
      v-if="drawerSector"
      :action="sectorFormAction"
      :sector-selected="sectorSelected"
      @onComplete="drawerSector = false"
    />
  </EndDrawer>

  <EndDrawer
    :open="drawerSectorDepartureAdvance"
    @update:open="drawerSectorDepartureAdvance = $event"
  >
    <SectorDepartureAdvanceForm
      v-if="drawerSectorDepartureAdvance"
      :action="sectorDepartureAdvanceFormAction"
      :sector-departure-advance-selected="sectorDepartureAdvanceSelected"
      @onComplete="drawerSectorDepartureAdvance = false"
    />
  </EndDrawer>

  <EndDrawer
    :open="drawerUploadSectorDepartureAdvance"
    @update:open="drawerUploadSectorDepartureAdvance = $event"
  >
    <UploadSectorDepartureAdvanceForm
      v-if="drawerUploadSectorDepartureAdvance"
      @loadSectors="loadSectors"
      @onComplete="drawerUploadSectorDepartureAdvance = false"
    />
  </EndDrawer>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import SectorForm from "./forms/Sector";
import SectorDepartureAdvanceForm from "./forms/SectorDepartureAdvance";
import UploadSectorDepartureAdvanceForm from "./forms/UploadSectorDepartureAdvance";

const store = useStore();

const drawerSector = ref(false);
const sectorFormAction = ref("create");
const sectorSelected = ref(null);
const useSectors = ref(false);
const sectorDepartureAdvanceSelected = ref(null);
const drawerSectorDepartureAdvance = ref(false);
const sectorDepartureAdvanceFormAction = ref("create");
const drawerUploadSectorDepartureAdvance = ref(false);

const getWork = computed(() => store.getters.getWork);
const getSectors = computed(() => store.getters.getSectors);

const loadSectors = async () => {
  await store.dispatch("getAllSectors");
};

const init = async () => {
  useSectors.value = getWork.value.useSectors || false;

  await loadSectors();
};

init();

const toggleCollapse = (sector) => {
  sector.isCollapsed = !sector.isCollapsed;
};

const onOpenDrawerSector = ({ action, sector = null }) => {
  sectorSelected.value = { ...sector };
  drawerSector.value = true;
  sectorFormAction.value = action;
};

const onOpenDrawerSectorDepartureAdvance = ({
  action,
  sectorDepartureAdvance = null,
}) => {
  sectorDepartureAdvanceSelected.value = { ...sectorDepartureAdvance };
  drawerSectorDepartureAdvance.value = true;
  sectorDepartureAdvanceFormAction.value = action;
};

const onOpenDrawerUploadSectorDepartureAdvance = () => {
  drawerUploadSectorDepartureAdvance.value = true;
};

const onSelectSectors = async () => {
  try {
    useSectors.value = !useSectors.value;

    await store.dispatch("updateWork", {
      work: {
        key: getWork.value.key,
        useSectors: useSectors.value,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
</script>
