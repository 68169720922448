<template>
  <Form
    @submit="onSave"
    :validation-schema="{
      name: 'required',
    }"
  >
    <div>
      <h4 class="mb-6 text-secondary">
        {{ action === "create" ? "Crear" : "Editar" }} sector
      </h4>
    </div>
    <div class="pb-4">
      <label class="text-dark font-semibold text-sm">Nombre</label>
      <Field v-model="name" name="name" v-slot="{ field }">
        <input
          v-bind="field"
          type="text"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
            mt-2
          "
          placeholder="Ingrese nombre del sector"
        />
      </Field>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm text-left">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="pb-4">
      <label class="text-dark font-semibold text-sm">Código (opcional)</label>
      <Field v-model="code" name="code" v-slot="{ field }">
        <input
          v-bind="field"
          type="text"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
            mt-2
          "
          placeholder="Ingrese código del sector"
        />
      </Field>
      <div>
        <ErrorMessage name="code" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm text-left">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="pb-4">
      <label class="text-dark font-semibold text-sm">Etapa (opcional)</label>
      <Field v-model="stage" name="stage" v-slot="{ field }">
        <input
          v-bind="field"
          type="text"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
            mt-2
          "
          placeholder="Ingrese etapa del sector"
        />
      </Field>
      <div>
        <ErrorMessage name="stage" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm text-left">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>

    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div class="text-left pb-10">
      <button
        class="rounded bg-primary py-2 px-8 text-white font-medium flex"
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  sectorSelected: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["onComplete"]);
const store = useStore();
const { value: name } = useField("name");
const { value: code } = useField("code");
const { value: stage } = useField("stage");

const loadingSave = ref(false);
const responseType = ref(null);
const responseMessage = ref(null);

const init = () => {
  if (props.action === "update") {
    name.value = props.sectorSelected.name;
    code.value = props.sectorSelected.code;
    stage.value = props.sectorSelected.stage;
  }
};

init();

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (props.action === "create")
      await store.dispatch("createSector", {
        sector: {
          name: values.name,
          code: values.code,
          stage: values.stage,
        },
      });
    else
      await store.dispatch("updateSector", {
        sector: {
          key: props.sectorSelected.key,
          name: values.name,
          code: values.code,
          stage: values.stage,
        },
      });

    resetForm();

    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message ||
      "Hubo un problema al guardar la información del sector/lote";
  } finally {
    loadingSave.value = false;
  }
};
</script>
